import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default function useAdminsList() {

    const toast = useToast()
    const refListTable = ref(null)
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'avatar', label: 'Avatar', sortable: false , tdClass:'width-200 p-1' },
        { key: 'name',label:'Name', sortable: false },
        { key: 'phone',label:'Phone', sortable: false },
        { key: 'group_name',label:'Group', sortable: false },
        { key: 'is_active',label:'Active', sortable: false },
        { key: 'actions' }       
    ]
    const perPage = ref(10)
    const totals = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totals.value,
        }
    })
    const refetchData = () => {
    
        refListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery], () => {
      
        refetchData()
    })
    const fetchAdmins = (ctx, callback)=>{
        const offset = perPage.value*(currentPage.value-1);
        store
        .dispatch('app-admin/fetchUsers', {
            perpage: perPage.value,
            offset:offset,
            keyword:searchQuery.value,
            groupType: 'admin'
        })
        .then(response => {
            
            
            const data = response.data.data;
            // console.log('owners',response.data);
            totals.value = response.data.total
        //  const { invoices, total } = response.data
            callback(data)
            // callback(invoices)
            //totalInvoices.value = total
        })
        .catch(() => {
            toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching Owner' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
        })
    
    }
    const deleteData = (id) => {
        //fetchDeleteProperty
        store.dispatch('app-admin/fetchDelete',id).then(response=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Delete Success",
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData();
        }).catch(()=>{
          toast({
            component: ToastificationContent,
            props: {
              title: "Error Delete Client",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
    
        })
        //console.log('id', id);
        
      }

    return {
        fetchAdmins,
      
        tableColumns,
        perPage,
        currentPage,
        totals,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        deleteData,
        refetchData,
    }
}